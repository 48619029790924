import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BG from "../assets/2015/11/MAZD0294-Mazda-Foundation-Website-V4_03.jpg"
import Img30 from "../assets/2022/12/MF_AnnualReportThumbnail.png"
import PDF30 from "../assets/2022/12/mazdafoundation_annual-reports-pdf_30th_Annual_Report_to_the_Community.pdf"
import Img31 from "../assets/2023/12/MF_AnnualReportThumbnail.jpg"
import PDF31 from "../assets/2023/12/Mazda_Foundation_31st_Annual_Report_297Hx210W_V4.pdf"
import Img29 from "../assets/2021/12/CHE2038.png"
import PDF29 from "../assets/2021/12/mazdafoundation_annual-reports-pdf_29th_Annual_Report_to_the_Community.pdf"
import Img28 from "../assets/2020/09/CHE2037.png"
import PDF28 from "../assets/2020/09/mazdafoundation_annual-reports-pdf_28th_Annual_Report_to_the_Community.pdf"
import Img27 from "../assets/2019/09/tile.png"
import PDF27 from "../assets/2019/09/mazdafoundation_annual-reports-pdf_27th_Annual_Report_to_the_Community.pdf"
import Img26 from "../assets/2019/06/CHE1848.png"
import PDF26 from "../assets/2019/06/mazdafoundation_annual-reports-pdf_26th_Annual_Report_to_the_Community.pdf"
import Img25 from "../assets/2017/02/25th-4-150x150.jpg"
import PDF25 from "../assets/2017/02/mazdafoundation_annual-reports-pdf_25th_Annual_Report_to_the_Community.pdf"
import Img22 from "../assets/2014/03/22nd-150x150.png"
import PDF22 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_22nd_Annual_Report_to_the_Community.pdf"
import Img21 from "../assets/2014/03/21st-150x150.png"
import PDF21 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_21st_Annual_Report_to_the_Community.pdf"
import Img20 from "../assets/2014/03/20th-320x210.png"
import PDF20 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_20th_Annual_Report_to_the_Community.pdf"
import Img19 from "../assets/2014/03/19th-320x210.png"
import PDF19 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_19th_Annual_Report_to_the_Community.pdf"
import Img2007 from "../assets/2014/03/2007-320x210.png"
import PDF2007 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_2007_Annual_Report_to_the_Community.pdf"
import Img2006 from "../assets/2014/03/2006-320x210.png"
import PDF2006 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_2006_Annual_Report_to_the_Community.pdf"
import Img2004 from "../assets/2014/03/2004-320x210.png"
import PDF2004 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_2004_Annual_Report_to_the_Community.pdf"
import Img2003 from "../assets/2014/03/2003-320x210.png"
import PDF2003 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_2003_Annual_Report_to_the_Community.pdf"
import Img2002 from "../assets/2014/03/2002-320x210.png"
import PDF2002 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_2002_Annual_Report_to_the_Community.pdf"
import Img2000 from "../assets/2014/03/2000-320x210.png"
import PDF2000 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_2000_Annual_Report_to_the_Community.pdf"
import Img99 from "../assets/2014/03/99-320x210.png"
import PDF99 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_1999_Annual_Report_to_the_Community.pdf"
import Img97 from "../assets/2014/03/97-320x210.png"
import PDF97 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_1997_Annual_Report_to_the_Community.pdf"
import Img96 from "../assets/2014/03/96-320x210.png"
import PDF96 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_1996_Annual_Report_to_the_Community.pdf"
import Img95 from "../assets/2014/03/95-320x210.png"
import PDF95 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_1995_Annual_Report_to_the_Community.pdf"
import Img9394 from "../assets/2014/03/93.94-320x210.png"
import PDF9394 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_93-94_Annual_Report_to_the_Community.pdf"
import Img92 from "../assets/2014/03/92-320x210.png"
import PDF92 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_1992_Annual_Report_to_the_Community.pdf"
import Img91 from "../assets/2014/03/91-320x210.png"
import PDF91 from "../assets/2014/03/mazdafoundation_annual-reports-pdf_1991_Annual_Report_to_the_Community.pdf"

const Reports = () => (
  <Layout page="reports">
    <SEO title="Report to the Community" />
    <div
      id="reports"
      style={{
        background: `url(${BG}) repeat`,
      }}
    >
      <div id="title" className="green-bg vertical-padding-smaller center">
        <div className="container">
          <div className="horizontal-padding">
            <h1>REPORT TO THE COMMUNITY</h1>
          </div>
        </div>
      </div>
      <div className="vertical-padding-small">
        <div className="container">
          <div id="reports">
            <div className="columns">
            <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img31}
                    alt="31st" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF31}
                      download>
                      Download
                    </a>
                  </div>
                </div>
                <p className="small">31st Annual Report to the Community</p>
              </div>
            <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img30}
                    alt="30th" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF30}
                      download>
                      Download
                    </a>
                  </div>
                </div>
                <p className="small">30th Annual Report to the Community</p>
              </div>
            <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img29}
                    alt="29th" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF29}
                      download>
                        Download
                    </a>
                  </div>
                </div>
                <p className="small">29th Annual Report to the Community</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img28}
                    alt="28th" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF28}
                      download>
                        Download
                    </a>
                  </div>
                </div>
                <p className="small">28th Annual Report to the Community</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img27}
                    alt="25th" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF27}
                      download>
                        Download
                    </a>
                  </div>
                </div>
                <p className="small">27th Annual Report to the Community</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img26}
                    alt="25th" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF26}
                      download>
                        Download
                    </a>
                  </div>
                </div>
                <p className="small">26th Annual Report to the Community</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img25}
                    alt="25th" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF25}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">25th Annual Report to the Community</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img22}
                    alt="22nd" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF22}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">22nd Annual Report to the Community</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img21}
                    alt="21st" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF21}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">21st Annual Report to the Community</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img20}
                    alt="20th" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF20}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">20th Annual Report to the Community</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img19}
                    alt="19th" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF19}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">19th Annual Report to the Community</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img2007}
                    alt="2007" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF2007}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">Report to the Community 2007</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img2006}
                    alt="2006" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF2006}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">Report to the Community 2006</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img2004}
                    alt="2004"  />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF2004}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">Report to the Community 2004</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img2003}
                    alt="2003" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF2003}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">Report to the Community 2003</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img 
                    src={Img2002}
                    alt="2002" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF2002}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">Report to the Community 2002</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img2000}
                    alt="2000" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF2000}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">“Report to the Community 2000</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img99}
                    alt="99" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF99}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">Report to the Community 1999</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img97}
                    alt="97" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF97}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">Report to the Community 1997</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img96}
                    alt="96" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF96}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">Report to the Community 1996</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img95}
                    alt="95" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF95}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">Report to the Community 1995</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img9394}
                    alt="93.94" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF9394}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">Report to the Community 1993/1994</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img92}
                    alt="92" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF92}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">Report to the Community 1992</p>
              </div>
              <div className="one_fourth column report">
                <div className="report_image">
                  <img
                    src={Img91}
                    alt="91" />
                  <div className="report_download">
                    <a
                      className="button"
                      href={PDF91}
                      download>
                        Download PDF
                    </a>
                  </div>
                </div>
                <p className="small">Report to the Community 1991</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Reports
